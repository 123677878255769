// Create a random password
export function getRandomPassword(settings) {
  // Determine length
  const length = settings.passwordLength;

  // Create characters map
  const characters = getCharacterMap(settings);

  // Create must contain list
  const mustContain = [];
  const activeOptions = getActiveSettingsAmount(settings);
  const containRatio = activeOptions / (activeOptions + 2) / activeOptions;
  const containAmount = Math.min(4, Math.max(1, Math.floor(containRatio * length)));

  [
    ["enableUppercaseCharacters", "uppercaseCharacters"],
    ["enableLowercaseCharacters", "lowercaseCharacters"],
    ["enableNumberCharacters", "numberCharacters"],
    ["enableSpecialCharacters", "specialCharacters"],
  ].forEach(function (item) {
    if (settings[item[0]]) {
      for (let i = 1; i <= containAmount; i++) {
        mustContain.push(getRandomChar(settings[item[1]]));
      }
    }
  });

  // Fill must contain list
  while (mustContain.length < length) {
    mustContain.push(getRandomChar(characters));
  }

  // Create password
  let password = "";
  while (mustContain.length) {
    const index = Math.floor(Math.random() * mustContain.length);
    password += mustContain[index];
    mustContain.splice(index, 1);
  }

  // Return password
  return password;
}

// Pick a ramdom character from a string
function getRandomChar(str) {
  return str.charAt(Math.floor(Math.random() * str.length));
}

// Get amount of toggled permutations
export function getActiveSettingsAmount(settings) {
  let activeOptions = 0;
  [
    "enableUppercaseCharacters",
    "enableLowercaseCharacters",
    "enableNumberCharacters",
    "enableSpecialCharacters",
  ].forEach(function (item) {
    if (settings[item]) {
      activeOptions++;
    }
  });

  return activeOptions;
}

// Get the characters usable for the password
function getCharacterMap(settings) {
  let characters = "";

  if (settings.enableUppercaseCharacters) {
    characters += settings.uppercaseCharacters;
  }

  if (settings.enableLowercaseCharacters) {
    characters += settings.lowercaseCharacters;
  }

  if (settings.enableNumberCharacters) {
    characters += settings.numberCharacters;
  }

  if (settings.enableSpecialCharacters) {
    characters += settings.specialCharacters;
  }

  return characters;
}

// Get strinified number from settings
export function getPossibilitiesEstimate(settings) {
  const permutations = countPermutations(settings);
  return stringifyNumber(permutations, settings.showFullPermutationNumber);
}

// Count permutations
function countPermutations(settings) {
  const characters = getCharacterMap(settings);
  const charactersAmount = characters.length;
  let passwordLength = settings.passwordLength;
  let permutations = charactersAmount;

  while (passwordLength > 1) {
    permutations = permutations * charactersAmount;
    passwordLength--;
  }
  return permutations;
}

function localDecimalSeparator() {
  var n = 1.1;
  n = n.toLocaleString().substring(1, 2);
  return n;
}

// Show a large number in text
function stringifyNumber(number, showFullPermutationNumber) {
  // Validate input
  if (!number) {
    return "";
  }

  // Return rounded number when not at least a billion
  if (number < 1000 * 1000 * 1000) {
    return number.toLocaleString();
  }

  // Return full number
  if (showFullPermutationNumber) {
    const numberStr = number.toLocaleString("fullwide", {
      useGrouping: true,
    });
    return numberStr;
  }

  // Number names
  const numberNames = [
    "Thousand",
    "Million", // https://en.wikipedia.org/wiki/Names_of_large_numbers
    "Billion",
    "Trillion",
    "Quadrillion",
    "Quintillion",
    "Sextillion",
    "Septillion",
    "Octillion",
    "Nonillion",
    "Decillion",
    "Undecillion",
    "Duodecillion",
    "Tredecillion",
    "Quattuordecillion",
    "Quindecillion",
    "Sedecillion",
    "Septendecillion",
    "Octodecillion",
    "Novemdecillion",
    "Vigintillion",
    "Unvigintillion",
    "Duovigintillion",
    "Tresvigintillion",
    "Quattuorvigintillion",
    "Quinvigintillion",
    "Sesvigintillion",
    "Septemvigintillion",
    "Octovigintillion",
    "Novemvigintillion",
    "Trigintillion",
    "Untrigintillion",
    "Duotrigintillion",
    "Trestrigintillion",
    "Quattuortrigintillion",
    "Quintrigintillion",
    "Sestrigintillion",
    "Septentrigintillion",
    "Octotrigintillion",
    "Noventrigintillion",
    "Quadragintillion",
    "Unquadragintillion", // https://en.wiktionary.org/wiki/Appendix:Very_large_numbers
    "Duoquadragintillion",
    "Trequadragintillion",
    "Quattuorquadragintillion",
  ];

  // Get the number as a string
  let numberStr = number.toLocaleString("fullwide", {
    useGrouping: false,
  });
  const numberStrOrg = numberStr;

  // Start a index null
  let index = 0;

  // Keep looping when string is at least 4 chars
  while (numberStr.length >= 4) {
    numberStr = numberStr.substring(0, numberStr.length - 3);
    index++;
  }

  // Add decimals
  const decimal = numberStrOrg.substring(
    numberStr.length,
    numberStr.length + 1
  );

  if (numberStr.length <= 2 && decimal !== "0") {
    numberStr +=
      localDecimalSeparator() +
      numberStrOrg.substring(numberStr.length, numberStr.length + 1);
  }

  // Substract 2 from index to fix wrong numbers
  index = index - 1;

  return numberStr + " " + numberNames[index];
}

// Copy to clipboard
export function copyToClipboard(text) {
  var input = document.createElement("input");
  input.setAttribute("value", text);
  document.body.appendChild(input);
  input.select();
  input.setSelectionRange(0, 1000);
  var result = document.execCommand("copy");
  document.body.removeChild(input);
  return result;
}
