import React from "react";
import "./scss/main.scss";

// Password functions
import {
  getRandomPassword,
  getActiveSettingsAmount,
  getPossibilitiesEstimate,
  copyToClipboard,
} from "./PasswordGenerator.js";

// Material ui slider
import Slider from "@mui/material/Slider";

// Material ui icons
import CheckSharpIcon from "@mui/icons-material/CheckSharp";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import ReplaySharpIcon from "@mui/icons-material/ReplaySharp";
// import SettingsSharpIcon from '@material-ui/icons/SettingsSharp';

class App extends React.Component {
  constructor(props) {
    super(props);

    // Default settings
    this.passwordsAmount = 5;
    this.enableUppercaseCharacters = true;
    this.enableLowercaseCharacters = true;
    this.enableNumberCharacters = true;
    this.enableSpecialCharacters = false;
    this.uppercaseCharacters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    this.lowercaseCharacters = "abcdefghijklmnopqrstuvwxyz";
    this.numberCharacters = "0123456789";
    this.specialCharacters = "!?.,;:@#$§%&*_+-±~=^<>()[]{}`'\"|\\/";
    // this.similarCharacters = "oO01Il|-~.,;:`'\""; // TODO
    // this.avoidSimilarCharacters = false; // TODO
    // this.avoidDuplicateCharacters = false; // TODO

    const state = {
      // Store passwords
      passwordsAmount: this.getSetting("passwordsAmount", this.passwordsAmount),

      // Uppercase
      enableUppercaseCharacters: this.getSetting(
        "enableUppercaseCharacters",
        this.enableUppercaseCharacters
      ),
      uppercaseCharacters: this.getSetting(
        "uppercaseCharacters",
        this.uppercaseCharacters
      ),

      // Lowercase
      enableLowercaseCharacters: this.getSetting(
        "enableLowercaseCharacters",
        this.enableLowercaseCharacters
      ),
      lowercaseCharacters: this.getSetting(
        "lowercaseCharacters",
        this.lowercaseCharacters
      ),

      // Numbers
      enableNumberCharacters: this.getSetting(
        "enableNumberCharacters",
        this.enableNumberCharacters
      ),
      numberCharacters: this.getSetting(
        "numberCharacters",
        this.numberCharacters
      ),

      // Special characters
      enableSpecialCharacters: this.getSetting(
        "enableSpecialCharacters",
        this.enableSpecialCharacters
      ),
      specialCharacters: this.getSetting(
        "specialCharacters",
        this.specialCharacters
      ),

      // Password length
      passwordLength: this.getSetting("passwordLength", 16),

      // Full permutation number
      showFullPermutationNumber: false,

      // Show advanced options
      advancedOptionsEnabled: false,
    };

    // Generate initial passwords
    state.passwords = this.generatePasswords(state);

    // Apply state
    this.state = state;

    // Bindings
    this.handleCharacterSettingClick = this.handleCharacterSettingClick.bind(
      this
    );
    this.handlePasswordLengthChange = this.handlePasswordLengthChange.bind(
      this
    );
    this.handlePermutationNumberClick = this.handlePermutationNumberClick.bind(
      this
    );
    this.toggleAdvancedOptions = this.toggleAdvancedOptions.bind(this);
    this.handleCharacterClick = this.handleCharacterClick.bind(this);
  }

  // Save a setting
  saveSetting(setting, value) {
    if (value === true) {
      value = "true";
    }
    if (value === false) {
      value = "false";
    }
    localStorage.setItem(setting, value);
  }

  // Get setting
  getSetting(setting, fallback) {
    const value = localStorage.getItem(setting);
    if (value === null) {
      return fallback;
    }
    if (localStorage.getItem(setting) === "true") {
      return true;
    }
    if (localStorage.getItem(setting) === "false") {
      return false;
    }
    if (setting === "passwordLength" || setting === "passwordsAmount") {
      return parseInt(value);
    }
    return localStorage.getItem(setting);
  }

  // Show full permutation number
  handlePermutationNumberClick() {
    this.setState({
      showFullPermutationNumber: !this.state.showFullPermutationNumber,
    });
  }

  // Handle settings
  handleCharacterSettingClick(setting) {
    let state = {};
    const newValue = !this.state[setting];
    state[setting] = newValue;
    if (getActiveSettingsAmount(this.state) <= 1 && !newValue) {
      return;
    }
    this.saveSetting(setting, newValue);
    this.setState(state, this.updatePasswords);
  }

  // Handle settings
  handlePasswordLengthChange(event, newValue) {
    newValue = parseInt(newValue);
    if (newValue !== this.state.passwordLength) {
      this.saveSetting("passwordLength", newValue);
      this.setState({ passwordLength: newValue }, this.updatePasswords);
    }
  }

  // Get the container for a random password
  getPasswordContainer(index, key) {
    // const password = getRandomPassword(this.state);
    const password = this.state.passwords[index];

    return (
      <div
        key={key}
        data-key={key}
        className="password__wrapper"
        onClick={(event) => {
          const copyElement = document.createElement("div");
          copyElement.classList.add("password__copy-notice");
          copyElement.innerHTML = "Copied!";
          copyElement.style.left = event.pageX + "px";
          copyElement.style.top = event.pageY + "px";
          document.body.appendChild(copyElement);

          setTimeout(function () {
            copyElement && document.body.removeChild(copyElement);
          }, 1760);

          copyToClipboard(password);
          const wrapper = document.querySelector(
            '.password__wrapper[data-key="' + key + '"]'
          );
          wrapper && wrapper.classList.add("password__wrapper--copied");
        }}
      >
        <div className="password__container">{password}</div>
      </div>
    );
  }

  // Get all password containers
  getPasswords() {
    let passwordContainers = [];

    if (!this.passwordContainerId) {
      this.passwordContainerId = 0;
    }

    for (let index = 0; index < this.state.passwords.length; index++) {
      this.passwordContainerId += 1;
      passwordContainers.push(
        this.getPasswordContainer(index, this.passwordContainerId)
      );
    }
    return passwordContainers;
  }

  updatePasswords() {
    this.setState({ passwords: this.generatePasswords(this.state) });
  }

  generatePasswords(settings) {
    const passwords = [];
    while (passwords.length < settings.passwordsAmount) {
      passwords.push(getRandomPassword(settings));
    }
    return passwords;
  }

  toggleAdvancedOptions() {
    this.setState({
      advancedOptionsEnabled: !this.state.advancedOptionsEnabled,
    });
  }

  getCharacters(characterType) {
    const characters = this[characterType];

    const characterContainers = [];
    for (var i = 0; i < characters.length; i++) {
      const character = characters.charAt(i);
      characterContainers.push(
        <div
          key={i}
          className={
            "noselect character-option__wrapper" +
            (this.state[
              "enable" +
              characterType.charAt(0).toUpperCase() +
              characterType.slice(1)
            ]
              ? ""
              : " character-option__wrapper--disabled") +
            (this.isCharacterUnselected(characterType, character)
              ? " character-option__wrapper--unselected"
              : "")
          }
          onClick={() => {
            this.handleCharacterClick(characterType, character);
          }}
        >
          <div className="character-option__container">{character}</div>
        </div>
      );
    }

    return (
      <div className="characters-option__wrapper">{characterContainers}</div>
    );
  }

  handleCharacterClick(characterType, character) {
    // Is the character currently disabled
    const isUnselected = this.isCharacterUnselected(characterType, character);

    // Abort if its the last of that type
    if (this.state[characterType].length <= 1 && !isUnselected) {
      return false;
    }

    // Abort if all the characters are disabled
    if (
      !this.state[
      "enable" +
      characterType.charAt(0).toUpperCase() +
      characterType.slice(1)
      ]
    ) {
      return false;
    }

    // All the characters possible
    const characters = this[characterType];

    let enabledCharacters = "";
    for (var i = 0; i < characters.length; i++) {
      const characterOriginal = characters.charAt(i);

      // Remove
      if (!isUnselected && characterOriginal === character) {
        continue;
      }

      // Add
      if (isUnselected && characterOriginal === character) {
        enabledCharacters += characterOriginal;
        continue;
      }

      // Only add others when needed
      if (!this.isCharacterUnselected(characterType, characterOriginal)) {
        enabledCharacters += characterOriginal;
      }
    }

    const state = {};
    state[characterType] = enabledCharacters;

    this.saveSetting(characterType, enabledCharacters);
    this.setState(state, this.updatePasswords);
  }

  isCharacterUnselected(characterType, character) {
    const characters = this.state[characterType];
    return characters.indexOf(character) === -1;
  }

  render() {
    return (
      <div className="app__wrapper">
        <header className="header__wrapper">
          <div className="header__container">
            <h1>
              Password
              <br />
              Generator
            </h1>
          </div>
        </header>
        <main className="content__wrapper">
          <div className="settings__wrapper">
            <div className="settings__container">
              <div className="settings__content">
                <div className="settings__options settings__options--column1">
                  <div
                    className={
                      "setting__container noselect" +
                      (this.state.enableUppercaseCharacters
                        ? " setting__container--active"
                        : "")
                    }
                    onClick={() => {
                      this.handleCharacterSettingClick(
                        "enableUppercaseCharacters"
                      );
                    }}
                  >
                    <div className="setting__checkbox">
                      <CheckSharpIcon className="setting__icon setting__icon--check" />
                      <ClearSharpIcon className="setting__icon setting__icon--clear" />
                    </div>
                    <div className="setting__label setting__label--monospace">
                      ABC
                    </div>
                  </div>
                  <div
                    className={
                      "setting__container noselect" +
                      (this.state.enableLowercaseCharacters
                        ? " setting__container--active"
                        : "")
                    }
                    onClick={() => {
                      this.handleCharacterSettingClick(
                        "enableLowercaseCharacters"
                      );
                    }}
                  >
                    <div className="setting__checkbox">
                      <CheckSharpIcon className="setting__icon setting__icon--check" />
                      <ClearSharpIcon className="setting__icon setting__icon--clear" />
                    </div>
                    <div className="setting__label setting__label--monospace">
                      abc
                    </div>
                  </div>
                </div>
                <div className="settings__options settings__options--column2">
                  <div
                    className={
                      "setting__container noselect" +
                      (this.state.enableNumberCharacters
                        ? " setting__container--active"
                        : "")
                    }
                    onClick={() => {
                      this.handleCharacterSettingClick(
                        "enableNumberCharacters"
                      );
                    }}
                  >
                    <div className="setting__checkbox">
                      <CheckSharpIcon className="setting__icon setting__icon--check" />
                      <ClearSharpIcon className="setting__icon setting__icon--clear" />
                    </div>
                    <div className="setting__label setting__label--monospace">
                      123
                    </div>
                  </div>
                  <div
                    className={
                      "setting__container noselect" +
                      (this.state.enableSpecialCharacters
                        ? " setting__container--active"
                        : "")
                    }
                    onClick={() => {
                      this.handleCharacterSettingClick(
                        "enableSpecialCharacters"
                      );
                    }}
                  >
                    <div className="setting__checkbox">
                      <CheckSharpIcon className="setting__icon setting__icon--check" />
                      <ClearSharpIcon className="setting__icon setting__icon--clear" />
                    </div>
                    <div className="setting__label  setting__label--monospace">
                      $%#
                    </div>
                  </div>
                  {/* <div
                    className={
                      "setting__button noselect setting__button--advanced" +
                      (this.state.advancedOptionsEnabled
                        ? " setting__button--advanced-active"
                        : "")
                    }
                    onClick={() => {
                      this.toggleAdvancedOptions();
                    }}
                  >
                    <SettingsSharpIcon className="setting__icon setting__icon--check" />
                  </div> */}
                  <div
                    className="setting__button noselect setting__button--randomize"
                    onClick={() => {
                      this.updatePasswords();
                    }}
                  >
                    <ReplaySharpIcon className="setting__icon setting__icon--check" />
                  </div>
                </div>
              </div>

              {/*this.state.advancedOptionsEnabled ? (
                <div className="advanced-options__wrapper">
                  <div className="advanced-options__container">
                    <div>
                    <div
                    className={
                      "setting__container noselect" +
                      (false
                        ? " setting__container--active"
                        : "")
                    }
                    onClick={() => {
                      this.handleCharacterSettingClick(
                        "enableNumberCharacters"
                      );
                    }}
                  >
                    <div className="setting__checkbox">
                      <CheckSharpIcon className="setting__icon setting__icon--check" />
                      <ClearSharpIcon className="setting__icon setting__icon--clear" />
                    </div>
                    <div className="setting__label">Avoid similar looking characters</div>
                  </div>

                    </div>
                    <div className="advanced-options__characters-label">
                      Uppercase
                    </div>
                    <div className="advanced-options__characters-content">
                      {this.getCharacters("uppercaseCharacters")}
                    </div>
                  </div>
                  <div className="advanced-options__container">
                    <div className="advanced-options__characters-label">
                      Lowercase
                    </div>
                    <div className="advanced-options__characters-content">
                      {this.getCharacters("lowercaseCharacters")}
                    </div>
                  </div>
                  <div className="advanced-options__container">
                    <div className="advanced-options__characters-label">
                      Numbers
                    </div>
                    <div className="advanced-options__characters-content">
                      {this.getCharacters("numberCharacters")}
                    </div>
                  </div>
                  <div className="advanced-options__container">
                    <div className="advanced-options__characters-label">
                      Special
                    </div>
                    <div className="advanced-options__characters-content">
                      {this.getCharacters("specialCharacters")}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )*/}

              <div className="password-length__wrapper noselect">
                <div className="password-length__container">
                  <Slider
                    value={this.state.passwordLength}
                    step={1}
                    valueLabelDisplay="on"
                    onChange={this.handlePasswordLengthChange}
                    min={8}
                    max={64}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="passwords__wrapper">
            <div className="passwords__container">{this.getPasswords()}</div>
          </div>
          <div className="permutations__wrapper">
            <div className="permutations__container">
              <div className="permutations__number-container">
                <div
                  className="permutations__number noselect"
                  onClick={this.handlePermutationNumberClick}
                >
                  {getPossibilitiesEstimate(this.state)}
                </div>
              </div>
              <div className="permutations__subline">possibilities</div>
            </div>
          </div>
        </main>
        <footer className="footer__wrapper">
          Created by{" "}
          <a href="https://stephanwagner.me" rel="noreferrer" target="_blank">
            Stephan Wagner
          </a>
        </footer>
      </div>
    );
  }
}

export default App;
